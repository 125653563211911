<template>
    <div>
  <div v-if="loader">
    Loading...
  </div>
  <div v-if="!loader"></div>
  <div class="singup_comman_banner">
                <div class="comman_banner">
                    <div class="container">
                        <div class="banner_logo">
                            <img src="./casuals_couriers/images/simpo_logo.svg" alt="Simpo" title="Simpo">
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="singup_page">
                        <div class="singup_title">
                            <h4>כניסה</h4>
                        </div>
                        <form @submit="sms" class="singup_form" >
                            <p class="error_text" >{{errs}}</p>
                            <div class="form-group">
                                <label>SMS יש להקליד את הקוד שהתקבל ב </label>
                                <input type="text" class="form-control"  v-model="sms_code">
                            </div>
                        
                            <div class="form-group">
                                <input type="submit" class="comman_anchor" value="כניסה">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
</div>
</template>

<script>
import BackendModel from "../../models/BackendModel";
// import PointGlobalHeader from "./PointGlobalHeader";
import("./casuals_couriers/css/style.css");
export default {
  name: "CasualCouriersLogin",
  components: {
    // PointGlobalHeader,
  },
  data() {
    return {
      loader: false,
      sms_code: "",
      errs: "",
      global_err: "",
      session_id:"",
    };
  },
  async mounted() {
    const backendModel = new BackendModel();
    this.loader = true;
    this.unit_id = this.$route.query.unit_id
    let casual_courier_session_id = backendModel.getCasualCourierId();
    this.session_id = casual_courier_session_id
    console.log(this.session_id)
    // let authRes = await backendModel.CasualCourierRequest(
    //   "/Api/service/backoffice/casual_courier_auth",
    //   { casual_courier_session_id }
    // );
    // if (typeof authRes == "undefined") {
    //   this.global_err = "Backend is down?";
    // } else if (authRes.data.res == "ERROR") {
    //   this.global_err = authRes.data.e;
    // } else if (authRes.data.res == "OK") {
    //   await this.$router.push({ path: "/casual_couriers/casual_couriers_add_package", query:{"unit_id": this.unit_id} });
    // }else if (authRes.data.res.casual_courier_session_id == "") {
    //     this.loader = false;
    // }
    this.loader = false;
    // this.errs = "";
  },
  methods: {
    async sms(e) {
      e.preventDefault();
      const backendModel = new BackendModel();
      let casual_courier_session_id = backendModel.getCasualCourierId();
      let res = await backendModel.CasualCourierRequest(
        "/Api/service/backoffice/verify_casual_courier_sms",
        { sms_code: this.sms_code, session_id: this.session_id }
      );
      if (res.data.res == "NOK" && res.data.err == "less_data") {
        this.errs = "קוד שגוי";
      }
      if (res.data.res == "NOK") {
        this.errs = "שגיאה";
      }
      if (res.data.res == "OK") {
        await this.$router.push({ path: "/casual_couriers/casual_couriers_add_package", query:{"unit_id": this.unit_id} });
        this.loader = false;
      }
    },
  },
};
</script>

<style>
@import "./casuals_couriers/css/style.css";
</style>
